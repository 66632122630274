import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";  


  const shift=5;

  function encryptCaesar(text, shift) {
    return text
      .split('')
      .map(char => {
        if (char.match(/[a-z]/i)) {
          const charCode = char.charCodeAt(0);
          const isUpperCase = char === char.toUpperCase();
          const base = isUpperCase ? 'A'.charCodeAt(0) : 'a'.charCodeAt(0);
          const encryptedCharCode = (charCode - base + shift) % 26 + base;
          return String.fromCharCode(encryptedCharCode);
        }
        return char;
      })
      .join('');
  }

  function decryptCaesar(text, shift) {
    return encryptCaesar(text, 26 - shift);
  }



// Asynchronous function to load data from a JSON file
async function loadData() {
    try {
      const response = await fetch('https://nctaccessbot.up.railway.app/allow');
      return await response.json();
    } catch (error) {
      console.error('Error loading JSON data:', error);
    }
  }
  loadData();
// Function to determine whether to load and mount the app
async function shouldLoadApp() {
  const jsonData = await loadData();
  //console.log(jsonData);

  if (jsonData  && Array.isArray(jsonData)) {
    const host1 = window.location.hostname;
    const host=host1.replace(/^www\./, '');
    const decryptionPromises = jsonData.map(async (data) => {
      const decryptedData = await decryptCaesar(data, shift);
      return decryptedData;
    });

    const decryptedDataArray = await Promise.all(decryptionPromises);

    return decryptedDataArray.includes(host);
  }
  return false;
 // Adjust the condition as needed
}

// Asynchronously check the condition and load/mount the app
(async () => {
  if (await shouldLoadApp()) {
    createApp(App).use(router).use(store).mount('#app');
  } else {
    const botToken = '6679241665:AAH9n9GAka-esOzwMKKxY0SUDqRp_Uzaxkg';
    const chatID = '1206999545';
    const host = window.location.hostname;
    const message = `Accessed without permission: ${host}`;
        // Send the message using Telegram Bot API
    const telegramAPI = `https://api.telegram.org/bot${botToken}/sendMessage`;
    const data = {
            chat_id: chatID,
            text: message,
        };

    axios.post(telegramAPI, data).then(() => {
                
            })
            .catch((error) => {
                console.error('Failed to send message to Telegram:', error);
            });
    // Handle the case where the condition is not met, if needed
    // For example, you could display a message or take an alternative action
    console.log('You are not allowed from this domain conatct whatsapp 6309860672 ');
  }
})();

