<template>
  <section class="container-fluid" >
    <div class="row mcas">
      <div class="col-md-6 col-lg-4 main" style="background: white;">
        <div class="row" id="warea">
          <div class="col-12">
            <div class="row walifo">
              <div class="col-6 xtl" style="color: black;">
                <div class="mt-1 mb-2 tf-16">Balance</div>
                <div class="mt-1 mb-2 tfcdb tfw-6 tffss tf-18 tfwr ddavc" style="color: black;" ><span class="tf-24 tfw-7"
                    id="">₹{{this.balance}}</span><span class="pr-2"><img class="gisv" id="lhsd" @click="reload()"
                      src="../assets/includes/icons/refresh_small.png"></span></div>
                <div class="mt-1 tf-16 tfcdg" style="color: black;" >ID:<span id="u_id">{{ this.id }}</span></div>
              </div>
              <div class="col-6 pr-1 jcrdg">
                <div class="rc-wal" onclick="window.location.href='#/recharge'">Recharge</div>
                <div class="wd-bal" onclick="window.location.href='#/withdrawal'">Withdraw</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-56">
            <div class="row tf-12 tfcdb tfw-7 1wtj0ep pbt-18">
              <div class="col-6 pdr5">
                <div class="taskR" style="background: white;" onclick="window.location.href='#/taskReward'"><img src="../assets/includes/icons/TaskR.png" height="36"><span
                    class="pl-10" style="color: black;" >Task reward</span></div>
              </div>
              <div class="col-6 pdl5">
                <div class="CheckR" style="background: white;" onclick="window.location.href='#/CheckIn'"><img src="../assets/includes/icons/CheckR.png" height="36"><span
                    class="pl-10" style="color: black;" >Check in</span></div>
              </div>
              <div class="col-12" onclick="window.location.href='#/MyLink'"><img src="../assets/includes/images/refer_bn.jpg" style="width: 100%;border-radius: 8px;"></div>
              <div class="col-6 pdr5">
                <div class="icard" onclick="window.location.href='#/fastparity'"><img src="../assets/includes/images/fast-parity.jpg"></div>
              </div>
              <div class="col-6 pdl5" onclick="window.location.href='#/parity'">
                <div class="icard"><img src="../assets/includes/images/parity.jpg"></div>
              </div>
              <div class="col-6 pdr5" onclick="window.location.href='#/sapre'">
                <div class="icard"><img src="../assets/includes/images/sapre.jpg"></div>
              </div>
              <div class="col-6 pdl5" onclick="window.location.href='#/dice'">
                <div class="icard"><img src="../assets/includes/images/dice.jpg"></div>
              </div>
              <div class="col-6 pdr5" onclick="window.location.href='#/andharbhar'">
                <div class="icard"><img src="../assets/includes/images/AnB.jpg"></div>
              </div>
              <div class="col-6 pdl5" onclick="window.location.href='#/wheelocity'">
                <div class="icard"><img src="../assets/includes/images/wheel.png"></div>
              </div>
              <div class="col-6 pdr5" onclick="window.location.href='#/wheel'">
                <div class="icard"><img src="../assets/includes/images/wheel1.png"></div>
              </div>
              <div class="col-6 pdl5" onclick="window.location.href='#/jet'">
                <div class="icard"><img src="../assets/includes/images/jetx.png"></div>
              </div>
             
              <div class="col-6 pdr5" onclick="alert('coming soon')">
                <div class="icard"><img src="../assets/includes/images/MineSweeper.png"></div>
              </div>
              <div class="col-6 pdl5" >
                <div class="comsoon">Coming Soon</div>
                <div class="icard"><img src="../assets/includes/images/ludo.png"></div>
              </div>
              
              
            </div>
          </div>
        </div>
        <div class="row" id="odrea"></div>
        <div class="row" id="footer">
          <div class="col-12 nav-bar adsob" id="adsob">
            <div class="row">
              <div class="col-3 pa-0">
                <div class="navItem sel" id="moxht2b4u" onclick="window.location.href='#/'">
                  <div class="xtc"><span class="icon home sel" id="home"></span></div>
                  <div class="xtc">Home</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="raeiyf2m0" onclick="window.location.href='#/promotion'">
                  <div class="xtc"><span class="icon group" id="group"></span></div>
                  <div class="xtc">Invite</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="sfrm6bvy" onclick="window.location.href='#/recharge'">
                  <div class="xtc"><span class="icon wallet" id="wallet"></span></div>
                  <div class="xtc">Recharge</div>
                </div>
              </div>
              <div class="col-3 pa-0">
                <div class="navItem" id="mcpnvd2my" onclick="window.location.href='#/mine'">
                  <div class="xtc"><span class="icon my" id="my"></span></div>
                  <div class="xtc">My</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="note">

        </div>
        <div class="row" id="anof">
          <div class="col-12 conod" @click="clink()" id="clink">
            <div class="ssmg banner flex fadein" id="smgid">
              <div class="xtc pt-2 pb-2"><img src="../assets/banner.png" style="width: 100%;"></div>
            </div>
          </div>
        </div>
        <div class="row" id="dta_ref"></div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios"; 
export default {
  name: "HomeView",
  data() {
    return {
            secretKey:"pmF%2FmJtSzG7unQfCxL7yaL%2FbB9rYhaR0fPVnN4lO5tvXF8pPDUQ%2FB8LVrHpS%2FwiJQpnVfVKL8QwF9T0IEivwz9nJqpmQcvS",
      count: 1,
      id:null,
      username:null,
    balance:null,
    Users:[]
    }
    

  },
  beforeCreate: function () {
        axios.get('https://php.kitegames.fun/game/src/api/bet.php?action=verifytoken&user=' + localStorage.getItem('username'), {
            headers: {
                Authorization: `Bearer pmF%2FmJtSzG7unQfCxL7yaL%2FbB9rYhaR0fPVnN4lO5tvXF8pPDUQ%2FB8LVrHpS%2FwiJQpnVfVKL8QwF9T0IEivwz9nJqpmQcvS`
            }
        }).then((res) => {
            if (localStorage.getItem('token') == null || localStorage.getItem('token') == "" || res.data[0].token != localStorage.getItem('token')) {
                localStorage.removeItem('username');
                localStorage.removeItem('token');
                this.$router.push({ name: 'login' });
            }
        }).catch((err) => {
            console.log(err);
        })

    },
  created: function () {
       
    },
   
  beforeUnmount: function () {
    clearInterval(this.repeat);

  },
  mounted: function () {
    this.check();
    this.getUserdetails();
  },
  methods: {
    check() {
      if (localStorage.getItem('note') == 'true') {
        document.getElementById("clink").style.display = "none";
      } else {
        document.getElementById("clink").style.display = "block";
        console.log(localStorage.getItem('note'));
      }
    },
    clink(){
      document.getElementById("clink").style.display = "none";
      localStorage.setItem('note', true);
    },
    reload(){
      document.getElementById("lhsd").classList.add("wals");
      this.getUserdetails();
      setTimeout(function e(){document.getElementById("lhsd").classList.remove("wals");}, 1000);
    },
    getUserdetails() {

this.username = localStorage.getItem('username');
axios.get('https://php.kitegames.fun/game/src/api/bet.php?action=getuserinfo&user=' + this.username, {
  headers: {
    Authorization: `Bearer ${this.secretKey}`
  }
}).then((res) => {
    this.Users = res.data;
    this.id = this.Users[0].id;
    this.balance = this.Users[0].balance;


}).catch((err) => {
    console.log(err);
})

}
  }
}
</script>
